import React from 'react'
import Layout from "../components/share/layout";
import Footer from '../components/share/footer';

const nosotros = () => {
	return (
		<Layout
			title="Privacidad | UPC Cultural"
      metaTitle="Privacidad | UPC Cultural"
      description=""
      subtitile=""
      metaDescripcin=""
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords=""
		>

		<div style={{ marginTop: '61px' }}>
		</div>

		<br/>

		<div className="upcc-content" style={{ padding: '40px' }}>
			
			<h3 style={{ textAlign: 'center' }}>
				AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES – UPC CULTURAL
			</h3>

			<br/>

			<p>Por medio del presente documento, autorizo que la Universidad Peruana de Ciencias
			Aplicadas S.A.C. (en adelante, UPC Cultural), identificada con RUC N° 20211614545 y
			domiciliada en Av. Prolongación Primavera 2390, Monterrico, Santiago de Surco, trate mis
			datos personales remitidos mediante formulario <b>"Suscríbete al boletín de UPC Cultural"</b>.
			<br/>
			He sido informado que mis datos personales serán almacenados en la base de datos
			denominado “Prospección” con Código de Registro RNPDP N° 01457 , por tiempo
			indefinido, dada la naturaleza de las finalidades que se describen a continuación, o hasta
			que decida revocar esta autorización.</p>

			<p>UPC Cultural tratará mis datos personales para las siguientes finalidades: (i) Enviarme el
			boletín informativo de UPC Cultural con la agenda de actividades culturales que
			administran; (ii) absolver mis dudas y sugerencias; (iii) generar estadísticas o información
			histórica, de manera anonimizada o disociada; y, (iv)</p>

			<p>Entiendo que UPC Cultural podrá tratar mis datos personales directamente o podrá
			contratar a terceros para la realización de las finalidades antes autorizadas, los cuales
			pueden encontrarse dentro o fuera del Perú; asimismo, entiendo que UPC Cultural y estos
			terceros han adoptado las medidas de seguridad, técnicas, legales y organizativas
			necesarias para resguardar mis datos personales.</p>

			<p>
				En caso existan nuevos encargados que pudieran tener acceso o tratar mis datos, UPC
			Cultural me comunicará sobre éstos vía correo electrónico o los podré encontrar en el
			listado actualizado en la página web:
			</p>

			<p>Finalmente, Autorización para el tratamiento de datos personales -
			UPC

			<br/>

			<a href="">
				https://upc.edu.pe/proteccion-de-datos/
			</a>
<br/>
			<a href="">
				https://encuestasupc.typeform.com/to/RmJwxz14?typeform-source=upc.edu.pe
			</a>
			
			</p>

			<br/><br/>
		</div>

			<Footer />
		</Layout>
	)
}

export default nosotros
